import * as basicLightbox from "basiclightbox";


const lightboxes = document.querySelectorAll(".js-launch-lightbox");

const videoLightboxes = document.querySelectorAll(".js-launch-video-lightbox");

for (let i = 0; i < lightboxes.length; i++) {
  lightboxes[i].addEventListener("click", function() {
    const img = this.getAttribute("data-image");
    const caption = this.getAttribute("data-caption");
    basicLightbox
      .create(
        `<div><img width="1400" height="900" src="${img}"></div><div class="u-body-copy u-content u-text-white">${caption}</div>`
      )
      .show();
  });
}

for (let i = 0; i < videoLightboxes.length; i++) {
  videoLightboxes[i].addEventListener("click", function() {
    basicLightbox
      .create(
        this.nextElementSibling.innerHTML
      )
      .show();

      if (!getParameterByName('launch-lightbox')) {
  document.querySelector('.basicLightbox iframe').src =  document.querySelector('.basicLightbox iframe').getAttribute('src') + '&autoplay=1'
      }

    
  });
}

// auto launch video lightbox
if (getParameterByName('launch-lightbox')) {
  document.querySelector('.js-launch-video-lightbox').click();
}

function getParameterByName(name) {
  var match = RegExp('[?&]' + name + '=([^&]*)').exec(window.location.search);
  return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
}

